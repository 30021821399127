<template>
  <aside id="sidebar" class="flex hidden fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 w-64 h-full duration-75 lg:flex transition-width" aria-label="Sidebar">
    <div class="flex relative flex-col flex-1 pt-0 min-h-0 bg-gray-50 border-r border-gray-200">
      <div class="overflow-y-auto py-5 px-3 h-full bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <ul class="space-y-2">
          <li v-on:click="sidebarElementClick" sidebar-dropdown v-if="authStore.hasRole(['technic', 'technic_read'])">
            <button type="button" class="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-technics" data-collapse-toggle="dropdown-technics">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.6 19.8C9.6 20.2774 9.41036 20.7352 9.07279 21.0728C8.73523 21.4104 8.27739 21.6 7.8 21.6C7.32261 21.6 6.86477 21.4104 6.52721 21.0728C6.18964 20.7352 6 20.2774 6 19.8C6 19.3226 6.18964 18.8648 6.52721 18.5272C6.86477 18.1896 7.32261 18 7.8 18C8.27739 18 8.73523 18.1896 9.07279 18.5272C9.41036 18.8648 9.6 19.3226 9.6 19.8ZM18 19.8C18 20.2774 17.8104 20.7352 17.4728 21.0728C17.1352 21.4104 16.6774 21.6 16.2 21.6C15.7226 21.6 15.2648 21.4104 14.9272 21.0728C14.5896 20.7352 14.4 20.2774 14.4 19.8C14.4 19.3226 14.5896 18.8648 14.9272 18.5272C15.2648 18.1896 15.7226 18 16.2 18C16.6774 18 17.1352 18.1896 17.4728 18.5272C17.8104 18.8648 18 19.3226 18 19.8Z" fill="#9CA3AF"/>
                <path d="M3.5999 4.79999C3.28164 4.79999 2.97642 4.92642 2.75137 5.15146C2.52633 5.3765 2.3999 5.68173 2.3999 5.99999V18C2.3999 18.3182 2.52633 18.6235 2.75137 18.8485C2.97642 19.0736 3.28164 19.2 3.5999 19.2H4.8599C4.99761 18.5218 5.36554 17.9121 5.90135 17.4742C6.43715 17.0362 7.10789 16.797 7.7999 16.797C8.49192 16.797 9.16265 17.0362 9.69846 17.4742C10.2343 17.9121 10.6022 18.5218 10.7399 19.2H11.9999C12.3182 19.2 12.6234 19.0736 12.8484 18.8485C13.0735 18.6235 13.1999 18.3182 13.1999 18V5.99999C13.1999 5.68173 13.0735 5.3765 12.8484 5.15146C12.6234 4.92642 12.3182 4.79999 11.9999 4.79999H3.5999ZM16.7999 8.39999C16.4816 8.39999 16.1764 8.52642 15.9514 8.75146C15.7263 8.9765 15.5999 9.28173 15.5999 9.59999V16.86C15.986 16.7811 16.3838 16.7791 16.7707 16.854C17.1576 16.9289 17.5259 17.0793 17.8547 17.2966C18.1834 17.5139 18.4661 17.7939 18.6866 18.1204C18.9072 18.447 19.0612 18.8139 19.1399 19.2H20.3999C20.7182 19.2 21.0234 19.0736 21.2484 18.8485C21.4735 18.6235 21.5999 18.3182 21.5999 18V12C21.5998 11.6818 21.4734 11.3766 21.2483 11.1516L18.8483 8.75159C18.6233 8.52653 18.3181 8.40006 17.9999 8.39999H16.7999Z" fill="#9CA3AF"/>
              </svg>
              <span class="flex-1 ml-3 text-left">Техника</span>
              <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>
            <ul id="dropdown-technics" class="hidden py-2 space-y-2">
              <li>
                <router-link :to="{name: 'Dashboard'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Каталог техники</router-link>
              </li>
              <template v-if="authStore.hasRole('technic')">
                <li>
                  <router-link :to="{name: 'Make'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Марки</router-link>
                </li>
                <li>
                  <router-link :to="{name: 'Models'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Модели</router-link>
                </li>
                <li>
                  <router-link :to="{name: 'CoolTypes'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Типы охлаждения</router-link>
                </li>
                <li>
                  <router-link :to="{name: 'EngineBuilds'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Модификации двигателя</router-link>
                </li>
                <li>
                  <router-link :to="{name: 'Fuels'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Топливо</router-link>
                </li>
                <li>
                  <router-link :to="{name: 'GearOperations'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Типы трансмиссии</router-link>
                </li>
                <li>
                  <router-link :to="{name: 'DriveTypes'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Типы привода</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'CategoryGroups'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Группы категорий</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'Attributes'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Атрибуты</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'Measures'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Единицы измерения</router-link>
                </li>
              </template>
            </ul>
          </li>
          <li v-on:click="sidebarElementClick" sidebar-dropdown v-if="authStore.hasRole('product')">
            <button type="button" class="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-products" data-collapse-toggle="dropdown-products">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 2.39999C9.34929 2.39999 7.20009 4.54919 7.20009 7.19999V8.39999H6.00009C5.38809 8.39999 4.8745 8.85961 4.8073 9.46801L3.6073 20.268C3.5701 20.6064 3.67809 20.946 3.90609 21.2004C4.13409 21.4548 4.45929 21.6 4.80009 21.6H19.2001C19.5409 21.6 19.8661 21.4548 20.0941 21.2004C20.3221 20.946 20.4301 20.6064 20.3929 20.268L19.1929 9.46801C19.1257 8.85961 18.6121 8.39999 18.0001 8.39999H16.8001V7.19999C16.8001 4.54919 14.6509 2.39999 12.0001 2.39999ZM14.4001 8.39999V7.19999C14.4001 5.87399 13.3261 4.79999 12.0001 4.79999C10.6741 4.79999 9.60009 5.87399 9.60009 7.19999V8.39999H14.4001ZM7.20009 12C7.20009 11.3376 7.73769 10.8 8.40009 10.8C9.06249 10.8 9.60009 11.3376 9.60009 12C9.60009 12.6624 9.06249 13.2 8.40009 13.2C7.73769 13.2 7.20009 12.6624 7.20009 12ZM15.6001 10.8C14.9377 10.8 14.4001 11.3376 14.4001 12C14.4001 12.6624 14.9377 13.2 15.6001 13.2C16.2625 13.2 16.8001 12.6624 16.8001 12C16.8001 11.3376 16.2625 10.8 15.6001 10.8Z" fill="#9CA3AF"/>
              </svg>
              <span class="flex-1 ml-3 text-left">Продукты</span>
              <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>
            <ul id="dropdown-products" class="hidden py-2 space-y-2">
              <li>
                <router-link :to="{name: 'Products'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Каталог продуктов</router-link>
              </li>
              <li>
                <router-link :to="{name: 'Marketings'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Маркетинговое позиционирование</router-link>
              </li>
              <li>
                <router-link :to="{name: 'Qvs'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Техническое позиционирование</router-link>
              </li>
              <li v-if="authStore.hasRole('admin')">
                <router-link :to="{name: 'ProductFeatures'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Особенности продуктов</router-link>
              </li>
            </ul>
          </li>
          <li v-on:click="sidebarElementClick" sidebar-dropdown  v-if="authStore.hasRole('admin')">
            <button type="button" class="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-specifications" data-collapse-toggle="dropdown-specifications">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.6444 1.58965C11.8664 1.47012 12.1336 1.47012 12.3556 1.58965L22.1056 6.83965C22.3485 6.97046 22.5 7.22409 22.5 7.5C22.5 7.77591 22.3485 8.02954 22.1056 8.16035L12.3556 13.4104C12.1336 13.5299 11.8664 13.5299 11.6444 13.4104L1.89443 8.16035C1.65149 8.02954 1.5 7.77591 1.5 7.5C1.5 7.22409 1.65149 6.97046 1.89443 6.83965L11.6444 1.58965Z" fill="#9CA3AF"/>
                <path d="M3.26468 10.6018L10.9333 14.731C11.5992 15.0896 12.4008 15.0896 13.0667 14.7311L20.7353 10.6018L22.1056 11.3396C22.3485 11.4704 22.5 11.7241 22.5 12C22.5 12.2759 22.3485 12.5295 22.1056 12.6603L12.3556 17.9103C12.1336 18.0299 11.8664 18.0299 11.6444 17.9103L1.89443 12.6603C1.65149 12.5295 1.5 12.2759 1.5 12C1.5 11.7241 1.65149 11.4704 1.89443 11.3396L3.26468 10.6018Z" fill="#9CA3AF"/>
                <path d="M10.9333 19.231L3.26468 15.1018L1.89443 15.8396C1.65149 15.9704 1.5 16.2241 1.5 16.5C1.5 16.7759 1.65149 17.0295 1.89443 17.1603L11.6444 22.4103C11.8664 22.5299 12.1336 22.5299 12.3556 22.4103L22.1056 17.1603C22.3485 17.0295 22.5 16.7759 22.5 16.5C22.5 16.2241 22.3485 15.9704 22.1056 15.8396L20.7353 15.1018L13.0667 19.2311C12.4008 19.5896 11.5992 19.5896 10.9333 19.231Z" fill="#9CA3AF"/>
              </svg>
              <span class="flex-1 ml-3 text-left">Спецификации и вязкости</span>
              <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>
            <ul id="dropdown-specifications" class="hidden py-2 space-y-2">
              <li>
                <router-link :to="{name: 'Specifications'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Спецификации</router-link>
              </li>
              <li>
                <router-link :to="{name: 'Viscosity'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Вязкости</router-link>
              </li>
              <li>
                <router-link :to="{name: 'Uses'}" class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Условия эксплуатации</router-link>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
          <li v-if="authStore.hasRole('admin')">
            <router-link :to="{name: 'Companies'}" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.1999 7.2V6C7.1999 5.04522 7.57919 4.12955 8.25432 3.45442C8.92945 2.77928 9.84512 2.4 10.7999 2.4H13.1999C14.1547 2.4 15.0704 2.77928 15.7455 3.45442C16.4206 4.12955 16.7999 5.04522 16.7999 6V7.2H19.1999C19.8364 7.2 20.4469 7.45286 20.897 7.90294C21.347 8.35303 21.5999 8.96348 21.5999 9.6V13.884C18.5275 15.0225 15.2765 15.6036 11.9999 15.6C8.7233 15.6036 5.47234 15.0225 2.3999 13.884V9.6C2.3999 8.96348 2.65276 8.35303 3.10285 7.90294C3.55293 7.45286 4.16338 7.2 4.7999 7.2H7.1999ZM9.5999 6C9.5999 5.68174 9.72633 5.37652 9.95137 5.15147C10.1764 4.92643 10.4816 4.8 10.7999 4.8H13.1999C13.5182 4.8 13.8234 4.92643 14.0484 5.15147C14.2735 5.37652 14.3999 5.68174 14.3999 6V7.2H9.5999V6ZM10.7999 12C10.7999 11.6817 10.9263 11.3765 11.1514 11.1515C11.3764 10.9264 11.6816 10.8 11.9999 10.8H12.0119C12.3302 10.8 12.6354 10.9264 12.8604 11.1515C13.0855 11.3765 13.2119 11.6817 13.2119 12C13.2119 12.3183 13.0855 12.6235 12.8604 12.8485C12.6354 13.0736 12.3302 13.2 12.0119 13.2H11.9999C11.6816 13.2 11.3764 13.0736 11.1514 12.8485C10.9263 12.6235 10.7999 12.3183 10.7999 12Z" fill="#9CA3AF"/>
                <path d="M2.3999 16.4304V19.2C2.3999 19.8365 2.65276 20.447 3.10285 20.8971C3.55293 21.3471 4.16338 21.6 4.7999 21.6H19.1999C19.8364 21.6 20.4469 21.3471 20.897 20.8971C21.347 20.447 21.5999 19.8365 21.5999 19.2V16.4304C18.5067 17.4729 15.264 18.0031 11.9999 18C8.6447 18 5.4155 17.448 2.3999 16.4304Z" fill="#9CA3AF"/>
              </svg>
              <span class="ml-3">Компании</span>
            </router-link>
          </li>
          <li v-if="authStore.hasRole('admin')">
            <router-link :to="{name: 'Users'}" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5999 12C21.5999 14.5461 20.5885 16.9879 18.7881 18.7882C16.9878 20.5886 14.546 21.6 11.9999 21.6C9.45382 21.6 7.01203 20.5886 5.21168 18.7882C3.41133 16.9879 2.3999 14.5461 2.3999 12C2.3999 9.45392 3.41133 7.01213 5.21168 5.21178C7.01203 3.41143 9.45382 2.4 11.9999 2.4C14.546 2.4 16.9878 3.41143 18.7881 5.21178C20.5885 7.01213 21.5999 9.45392 21.5999 12ZM14.3999 8.4C14.3999 9.03652 14.147 9.64697 13.697 10.0971C13.2469 10.5471 12.6364 10.8 11.9999 10.8C11.3634 10.8 10.7529 10.5471 10.3028 10.0971C9.85276 9.64697 9.5999 9.03652 9.5999 8.4C9.5999 7.76348 9.85276 7.15303 10.3028 6.70295C10.7529 6.25286 11.3634 6 11.9999 6C12.6364 6 13.2469 6.25286 13.697 6.70295C14.147 7.15303 14.3999 7.76348 14.3999 8.4ZM11.9999 13.2C10.851 13.1998 9.72623 13.5294 8.7592 14.1497C7.79217 14.77 7.02355 15.6549 6.5447 16.6992C7.21991 17.4847 8.05703 18.1149 8.99864 18.5466C9.94024 18.9782 10.9641 19.2011 11.9999 19.2C13.0357 19.2011 14.0596 18.9782 15.0012 18.5466C15.9428 18.1149 16.7799 17.4847 17.4551 16.6992C16.9762 15.6549 16.2076 14.77 15.2406 14.1497C14.2736 13.5294 13.1488 13.1998 11.9999 13.2Z" fill="#9CA3AF"/>
              </svg>
              <span class="ml-3">Пользователи</span>
            </router-link>
          </li>
          <li v-if="authStore.hasRole('admin')">
            <router-link :to="{name: 'ApiKeys'}" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
              <svg class="w-8" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5999 9.6C21.6002 10.7246 21.3371 11.8336 20.8316 12.8383C20.3262 13.8429 19.5924 14.7151 18.6891 15.385C17.7859 16.055 16.7382 16.5041 15.6301 16.6962C14.5221 16.8884 13.3844 16.8183 12.3083 16.4916L11.9999 16.8L10.7999 18L9.5999 19.2H7.1999V21.6H2.3999V16.8L7.5083 11.6916C7.20884 10.7016 7.12618 9.65873 7.26595 8.63393C7.40573 7.60914 7.76465 6.62648 8.31829 5.75285C8.87193 4.87922 9.6073 4.13513 10.4743 3.57122C11.3414 3.00731 12.3197 2.63682 13.3428 2.48496C14.3659 2.33311 15.4097 2.40346 16.4031 2.69122C17.3966 2.97898 18.3163 3.4774 19.0999 4.15255C19.8834 4.82771 20.5123 5.66375 20.9437 6.60376C21.3751 7.54378 21.5989 8.56571 21.5999 9.6ZM14.3999 4.8C14.0816 4.8 13.7764 4.92642 13.5514 5.15147C13.3263 5.37651 13.1999 5.68174 13.1999 6C13.1999 6.31826 13.3263 6.62348 13.5514 6.84852C13.7764 7.07357 14.0816 7.2 14.3999 7.2C15.0364 7.2 15.6469 7.45285 16.097 7.90294C16.547 8.35303 16.7999 8.96348 16.7999 9.6C16.7999 9.91826 16.9263 10.2235 17.1514 10.4485C17.3764 10.6736 17.6816 10.8 17.9999 10.8C18.3182 10.8 18.6234 10.6736 18.8484 10.4485C19.0735 10.2235 19.1999 9.91826 19.1999 9.6C19.1999 8.32696 18.6942 7.10606 17.794 6.20588C16.8938 5.30571 15.6729 4.8 14.3999 4.8Z" fill="#9CA3AF"/>
              </svg>
              <span class="ml-3">Доступы для сайтов и приложений</span>
            </router-link>
          </li>
          <li v-if="authStore.hasRole('admin')">
            <router-link :to="{name: 'UserLog'}" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.7999 5.7648C9.49156 5.12779 8.05508 4.79781 6.5999 4.8C5.0939 4.8 3.6683 5.148 2.3999 5.7648V17.7648C3.70826 17.1278 5.14473 16.7979 6.5999 16.8C8.6027 16.8 10.4615 17.412 11.9999 18.462C13.5912 17.3763 15.4735 16.7969 17.3999 16.8C18.9059 16.8 20.3315 17.148 21.5999 17.7648V5.7648C20.2916 5.12779 18.8551 4.79781 17.3999 4.8C15.8939 4.8 14.4683 5.148 13.1999 5.7648V14.4C13.1999 14.7183 13.0735 15.0235 12.8484 15.2485C12.6234 15.4736 12.3182 15.6 11.9999 15.6C11.6816 15.6 11.3764 15.4736 11.1514 15.2485C10.9263 15.0235 10.7999 14.7183 10.7999 14.4V5.7648Z" fill="#9CA3AF"/>
              </svg>
              <span class="ml-3">Лог изменений</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'News'}" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M18 3C17.9999 2.82961 17.9563 2.66207 17.8733 2.51327C17.7902 2.36448 17.6706 2.23936 17.5256 2.14981C17.3806 2.06025 17.2152 2.00921 17.045 2.00155C16.8748 1.99388 16.7054 2.02984 16.553 2.106L8.763 6H5C4.20435 6 3.44129 6.31607 2.87868 6.87868C2.31607 7.44129 2 8.20435 2 9C2 9.79565 2.31607 10.5587 2.87868 11.1213C3.44129 11.6839 4.20435 12 5 12H5.28L7.051 17.316C7.11735 17.5152 7.24472 17.6885 7.41505 17.8112C7.58538 17.934 7.79004 18.0001 8 18H9C9.26522 18 9.51957 17.8946 9.70711 17.7071C9.89464 17.5196 10 17.2652 10 17V12.618L16.553 15.894C16.7054 15.9702 16.8748 16.0061 17.045 15.9985C17.2152 15.9908 17.3806 15.9398 17.5256 15.8502C17.6706 15.7606 17.7902 15.6355 17.8733 15.4867C17.9563 15.3379 17.9999 15.1704 18 15V3Z" fill="#9CA3AF"/>
              </svg>
              <span class="ml-3">Новостная лента</span>
              <div v-if="newsStore.newsCount" class="w-5 h-5 ml-3 pt-0.5 rounded-full text-xs text-center font-medium bg-menu-light text-menu-dark">{{ newsStore.newsCount }}</div>
            </router-link>
          </li>
          <li v-on:click="sidebarElementClick" sidebar-dropdown  v-if="authStore.hasRole('admin')">
            <button type="button" class="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-analytics" data-collapse-toggle="dropdown-analytics">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4003 8.4C14.082 8.4 13.7768 8.27357 13.5518 8.04853C13.3267 7.82348 13.2003 7.51826 13.2003 7.2C13.2003 6.88174 13.3267 6.57652 13.5518 6.35147C13.7768 6.12643 14.082 6 14.4003 6H20.4003C20.7186 6 21.0238 6.12643 21.2488 6.35147C21.4739 6.57652 21.6003 6.88174 21.6003 7.2V13.2C21.6003 13.5183 21.4739 13.8235 21.2488 14.0485C21.0238 14.2736 20.7186 14.4 20.4003 14.4C20.082 14.4 19.7768 14.2736 19.5518 14.0485C19.3267 13.8235 19.2003 13.5183 19.2003 13.2V10.0968L14.0487 15.2484C13.8237 15.4734 13.5185 15.5997 13.2003 15.5997C12.8821 15.5997 12.5769 15.4734 12.3519 15.2484L9.60029 12.4968L4.44869 17.6484C4.22237 17.867 3.91925 17.9879 3.60461 17.9852C3.28998 17.9825 2.989 17.8563 2.76651 17.6338C2.54402 17.4113 2.41782 17.1103 2.41508 16.7957C2.41235 16.481 2.5333 16.1779 2.75189 15.9516L8.75189 9.9516C8.97693 9.72664 9.2821 9.60026 9.60029 9.60026C9.91849 9.60026 10.2237 9.72664 10.4487 9.9516L13.2003 12.7032L17.5035 8.4H14.4003Z" fill="#9CA3AF"/>
              </svg>
              <span class="flex-1 ml-3 text-left">Аналитика</span>
              <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>
            <ul id="dropdown-analytics" class="hidden py-2 space-y-2">
              <li>
                <router-link :to="{name: 'Coverage'}" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
                  <span class="ml-3">Аналитики запросов</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'ComponentCoverage'}" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
                  <span class="ml-3">Покрытие узлов</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'AutoCoverage'}" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
                  <span class="ml-3">Покрытие автопарка</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'SearchStatistic'}" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
                  <span class="ml-3">Статистика поиска</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="hidden absolute" sidebar-bottom-menu></div>
    </div>
  </aside>
</template>

<script setup>
import { useAuthStore } from "../../../stores/AuthStore.js";
import { useInterfaceStore } from "../../../stores/InterfaceStore.js";
import { useNewsStore} from "../../../stores/NewsStore.js";
import { initFlowbite } from 'flowbite'
import { onMounted } from 'vue'
import { toggleSidebar } from "../../../utilits/sidebar.js";

const interfaceStore = useInterfaceStore();
const newsStore = useNewsStore();

newsStore.getNewsCount();

onMounted(() => {
  initFlowbite();
})
const authStore = useAuthStore();

const sidebarElementClick = () => {
  interfaceStore.expendSidebar()
  toggleSidebar(interfaceStore.sidebarExpanded)
}

</script>