<template>
  <div @click="toggleMakesDropdown(true)" v-click-outside="() => toggleMakesDropdown(false)" class="w-full relative">{{ itemsList.value}}
    <div>
      <input
          v-model="inputValue"
          class="w-full flex bg-gray-50 border-gray-300 text-gray-900 focus:ring-gray-500 focus:border-gray-500 border text-sm rounded-lg p-2.5 disabled:bg-gray-200"
          type="text"
          :placeholder="filter.title + (activeCheckbox.length ? ` (${activeCheckbox.length})` : '')"
          :disabled="filter?.list.length ? false : true"
      >
      <div v-if="filter?.list.length" ref="makesDropdown" id="makesDropdown" class="absolute w-full z-50 border bg-white shadow-xl rounded hidden">
        <ul class="divide-y max-h-52 overflow-y-auto overflow-x-hidden" @change="setActiveCheckbox(filter.type, activeCheckbox)">
          <li class="flex items-center">
            <input :id="filter.type + '-selectAll'" type="checkbox" @change="setSelectAll(itemsList.length ? itemsList : filter?.list)" v-model="selectAll"
                   class="w-4 ml-2.5 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 checked:bg-blue-700 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
            <label :for="filter.type + '-selectAll'" class="w-full p-2.5 text-sm font-medium text-gray-900 dark:text-gray-100 overflow-hidden whitespace-nowrap text-ellipsis">Выбрать всё</label>
          </li>
          <li v-for="item of inputValue ? itemsList : filter?.list" :key="item.id" class="flex items-center" :title="item.name">
            <input :id="filter.type + '-' + item.id" type="checkbox" :value="item.id" :checked="item.attached" v-model="activeCheckbox"
                   class="w-4 ml-2.5 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 checked:bg-blue-700 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
            <label :for="filter.type + '-' + item.id" class="w-full p-2.5 text-sm font-medium text-gray-900 dark:text-gray-100 overflow-hidden whitespace-nowrap text-ellipsis">{{ item.name }}</label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {CoverageFilterStore} from "../../../stores/coverageFilterStore.js";

const coverageFilter = CoverageFilterStore()
const isUpdate = computed(() => coverageFilter.isLoadedReactive);

const selectAll = ref(false)
const makesDropdown = ref()
const inputValue = ref('')
const itemsList = ref([])
const activeCheckbox = ref([])

const props = defineProps({
  filter: Array,
})

const setSelectAll = (list = []) => {
  if(activeCheckbox.value.length === list.length) {
    activeCheckbox.value = []
    selectAll.value = false
  }
  else {
    activeCheckbox.value = list.map(el => { return el.id })
    selectAll.value = true
  }
}

const setActiveCheckbox = (code = '', selects = []) => {
  coverageFilter.addParamReactiveFilter(code, selects)
}

const toggleMakesDropdown = (focus) => {
  if (focus) makesDropdown.value?.classList.remove('hidden');
  else makesDropdown.value?.classList.add('hidden');
}

watch(inputValue, () => {
  itemsList.value = props.filter.list.filter(function(el) {
    return el.name.toUpperCase().includes(inputValue.value.toUpperCase())
  })
})

watch(isUpdate, () => {
  if (isUpdate.value) {
    let activeKey = Object.keys(coverageFilter.activeParamFilter).find(key => key === props.filter.type)
    if (activeKey) activeCheckbox.value = coverageFilter.activeParamFilter[activeKey]
    else activeCheckbox.value = []
  }
})

onMounted(() => {
  if (props.filter?.list.length) itemsList.value = props.filter?.list
})
</script>
